<template>
	<!-- container -->
	<div id="container">
		<!-- titArea -->
		<div class="titArea">
			<div class="inner">
				<h2>사용설명서</h2>
				<a @click="onBuilding()" class="btnBack">이전 페이지</a>
			</div>
		</div>
		<!--// titArea -->
		<!-- contents -->
		<div class="contents">
			<div class="manualArea">
				<h3>메인</h3>
				<div class="inBox">
					<div class="imgArea">
						<img src="/resources/images/buildingTip/img05-1.png" alt="" />
						<img style="width:70%;" src="/resources/images/buildingTip/img05-1m.png" alt="" />
					</div>
					<div class="txtArea">
						<div>
							<h4 class="depth">메인</h4>
							<h4>메인 화면으로 관리할 건물 목록 및 작성된 메모 목록을 표시하는 화면입니다.</h4>
							<dl>
								<dt><span>1.</span> 건물목록</dt>
								<dd>- 건물 목록을 건물 사진과 건물명으로 표시합니다.</dd>
							</dl>
							<dl>
								<dt><span>2.</span> 메모목록</dt>
								<dd>- 등록된 건물에 작성된 메모 목록을 표시합니다.</dd>
							</dl>
							<dl>
								<dt><span>3.</span> 메모조회</dt>
								<dd>- 간단한 메모 정보를 표시합니다.</dd>
							</dl>
							<dl>
								<dt><span>4.</span> 관리건물 추가</dt>
								<dd>- 관리건물 추가 버튼을 클릭하면 건물을 신규 등록하는 화면으로 이동합니다.</dd>
							</dl>
						</div>
					</div>
				</div>
			</div>
			<div class="manualArea">
				<h3>건물 등록</h3>
				<div class="inBox">
					<div class="imgArea">
						<img src="/resources/images/buildingTip/img05-2.png" alt="" />
						<img style="width:70%;" src="/resources/images/buildingTip/img05-2m.png" alt="" />
					</div>
					<div class="txtArea">
						<div>
							<h4 class="depth">메인 > 건물 상세 > 건물 등록</h4>
							<h4>선택한 건물 정보 조회 및 등록하는 화면입니다.</h4>
							<dl>
								<dt><span>1.</span> 건물 영역</dt>
								<dd>- 지상, 지하 층 안내 이미지를 표시합니다.</dd>
							</dl>
							<dl>
								<dt><span>2.</span> 건물명</dt>
								<dd>- 등록할 건물명을 입력합니다.</dd>
							</dl>
							<dl>
								<dt><span>3.</span> 건물지상층</dt>
								<dd>- 건물 지상 층수를 변경합니다. 1층 ~ 최대 20층까지 선택 가능합니다.</dd>
							</dl>
							<dl>
								<dt><span>4.</span> 옥상</dt>
								<dd>- 옥상포함을 선택하여 옥상을 만들 수 있습니다.</dd>
							</dl>
							<dl>
								<dt><span>5.</span> 건물지하층</dt>
								<dd>- 건물 지하 층수를 변경합니다. 1층 ~ 최대 5층까지 선택 가능합니다.</dd>
							</dl>
							<dl>
								<dt><span>6.</span> 건물 주소 입력</dt>
								<dd>- 주소선택 버튼을 클릭하고, 우편번호를 기반으로 건물 주소를 입력합니다.</dd>
							</dl>
							<dl>
								<dt><span>7.</span> 건물 사진 첨부</dt>
								<dd>- 건물 사진을 변경합니다.</dd>
							</dl>
							<dl>
								<dt><span>8.</span> 버튼 영역</dt>
								<dd>- 취소: 건물 등록 작업을 최소하고 메인화면으로 이동합니다.</dd>
								<dd>- 건물등록: 건물 정보를 저장하고 메인화면으로 이동합니다.</dd>
							</dl>
						</div>
					</div>
				</div>
			</div>
			<div class="manualArea">
				<h3>건물 상세(층목록)</h3>
				<div class="inBox">
					<div class="imgArea">
						<img src="/resources/images/buildingTip/img05-3.png" alt="" />
						<img style="width:70%;" src="/resources/images/buildingTip/img05-3m.png" alt="" />
					</div>
					<div class="txtArea">
						<div>
							<h4 class="depth">메인 > 건물 상세</h4>
							<h4>선택한 건물에 대한 상세한 정보를 표시하는 화면입니다.</h4>
							<dl>
								<dt><span>1.</span> 이미지영역</dt>
								<dd>- 건물 사진이 등록된 경우 건물 사진이 표시되며, 등록 안된 경우 층 안내 이미지를 표시합니다.</dd>
							</dl>
							<dl>
								<dt><span>2.</span> 탭 영역</dt>
								<dd>- 건물의 층 목록, 스케줄, 글 목록으로 변경 할 수 있습니다.</dd>
							</dl>
							<dl>
								<dt><span>3.</span> 버튼 영역</dt>
								<dd>- 프로젝트 버튼 영역: 프로젝트 관리 화면으로 이동한니다.</dd>
								<dd>- 입주사 버튼 영역: 입주사 관리 화면으로 이동합니다.</dd>
							</dl>
							<dl>
								<dt><span>4.</span> 층별 관리 영역</dt>
								<dd>- 관리 건물에 등록된 층 목록을 표시합니다. 앞의 B는 지하창을 의미합니다.</dd>
							</dl>
							<dl>
								<dt><span>5.</span> 층 정보</dt>
								<dd>- 층에 대한 간단한 정보를 표시합니다.</dd>
								<dd>- 도면이 등록된 층인 경우 도면 이미지가 표시되며 없으면 기본 아이콘이 표시됩니다.</dd>
							</dl>
							<dl>
								<dt><span>6.</span> 설정</dt>
								<dd>- 현재 건물 정보를 수정합니다. 클릭할 경우 건물 수정 화면으로 이동합니다.</dd>
							</dl>
							<dl>
								<dt><span>7.</span> 층상세 이동</dt>
								<dd>- 선택한 층에 대한 상세 조회 화면으로 이동합니다.</dd>
							</dl>
						</div>
					</div>
				</div>
			</div>
			<div class="manualArea">
				<h3>건물 상세(스케줄)</h3>
				<div class="inBox">
					<div class="imgArea">
						<img src="/resources/images/buildingTip/img05-4.png" alt="" />
						<img style="width:70%;" src="/resources/images/buildingTip/img05-4m.png" alt="" />
					</div>
					<div class="txtArea">
						<div>
							<h4 class="depth">메인 > 건물 상세</h4>
							<h4>선택한 건물에 대한 상세한 정보를 표시하는 화면입니다.</h4>
							<dl>
								<dt><span>1.</span> 타이틀 날짜 영역</dt>
								<dd>- 선택된 일자에 대한 년도와 월을 표시합니다.</dd>
							</dl>
							<dl>
								<dt><span>2.</span> 주간 영역</dt>
								<dd>- 좌우 버튼을 눌러 이전주와 다음주로 이동합니다.</dd>
							</dl>
							<dl>
								<dt><span>3.</span> 일 영역</dt>
								<dd>- 해당 일자를 클릭하여 해당일에 대한 스케줄과 프로젝트를 표시합니다.</dd>
								<dd>- 해당 일자에 스케줄이나 프로젝트가 포함되는 경우 빨간색 점으로 표시됩니다.</dd>
							</dl>
							<dl>
								<dt><span>4.</span> 목록 영역</dt>
								<dd>- 스케줄을 표시합니다. 해당 스케줄 클릭 시 작성되어 있는 메모로 이동합니다.</dd>
								<dd>- 프로젝트를 표시합니다. 해당 프로젝트 클릭 시 프로젝트 메모 목록으로 이동합니다.</dd>
							</dl>
						</div>
					</div>
				</div>
			</div>
			<div class="manualArea">
				<h3>건물 상세(글목록)</h3>
				<div class="inBox">
					<div class="imgArea">
						<img src="/resources/images/buildingTip/img05-5.png" alt="" />
						<img style="width:70%;" src="/resources/images/buildingTip/img05-5m.png" alt="" />
					</div>
					<div class="txtArea">
						<div>
							<h4 class="depth">메인 > 건물 상세</h4>
							<h4>선택한 건물에 대한 상세한 정보를 표시하는 화면입니다.</h4>
							<dl>
								<dt><span>1.</span> 네비게이션</dt>
								<dd>- 해당 메모가 사용된 위치정보를 표시합니.</dd>
							</dl>
							<dl>
								<dt><span>2.</span> 닉네임 영역</dt>
								<dd>- 메모 작성자를 표시합니다.</dd>
							</dl>
							<dl>
								<dt><span>3.</span> 내용 영역</dt>
								<dd>- 스케줄과 프로젝트가 포함되어있다면 해당 내용을 표시합니다.</dd>
								<dd>- 작성된 메모 내용을 표시합니다</dd>
							</dl>
							<dl>
								<dt><span>4.</span> 아이콘 영역</dt>
								<dd>- 댓글 개수 : 해당 메모의 댓글 개수를 표시합니다.</dd>
								<dd>- 조회수: 해당 메모를 읽은 공유회원의 숫자를 표시합니다.</dd>
							</dl>
							<dl>
								<dt><span>5.</span> 선택 영역</dt>
								<dd>- 선택한 메모에 대한 상세 조회 화면으로 이동합니다.</dd>
							</dl>
						</div>
					</div>
				</div>
			</div>
			<div class="manualArea">
				<h3>건물 수정</h3>
				<div class="inBox">
					<div class="imgArea">
						<img src="/resources/images/buildingTip/img05-6.png" alt="" />
						<img style="width:70%;" src="/resources/images/buildingTip/img05-6m.png" alt="" />
					</div>
					<div class="txtArea">
						<div>
							<h4 class="depth">메인 > 건물 상세 > 건물 수정</h4>
							<h4>선택한 건물 정보 조회 및 수정하는 화면입니다.</h4>
							<dl>
								<dt><span>1.</span> 건물 영역</dt>
								<dd>- 지상, 지하 층 안내 이미지를 표시합니다.</dd>
							</dl>
							<dl>
								<dt><span>2.</span> 건물명</dt>
								<dd>- 수정할 건물명을 입력합니다.</dd>
							</dl>
							<dl>
								<dt><span>3.</span> 건물지상층</dt>
								<dd>- 건물 지상 층수를 변경합니다. 1층 ~ 최대 20층까지 선택 가능합니다.</dd>
							</dl>
							<dl>
								<dt><span>4.</span> 옥상</dt>
								<dd>- 옥상포함을 선택하여 옥상을 만들 수 있습니다.</dd>
							</dl>
							<dl>
								<dt><span>5.</span> 건물지하층</dt>
								<dd>- 건물 지하 층수를 변경합니다. 1층 ~ 최대 5층까지 선택 가능합니다.</dd>
							</dl>
							<dl>
								<dt><span>6.</span> 건물 주소 입력</dt>
								<dd>- 주소선택 버튼을 클릭하고, 우편번호를 기반으로 건물 주소를 입력합니다.</dd>
							</dl>
							<dl>
								<dt><span>7.</span> 건물 사진 첨부</dt>
								<dd>- 건물 사진을 변경합니다.</dd>
							</dl>
							<dl>
								<dt><span>8.</span> 버튼 영역</dt>
								<dd>- 취소: 건물 수정 작업을 최소하고 메인화면으로 이동합니다.</dd>
								<dd>- 건물등록: 건물 정보를 저장하고 메인화면으로 이동합니다.</dd>
							</dl>
						</div>
					</div>
				</div>
			</div>
			<div class="manualArea">
				<h3>프로젝트 관리</h3>
				<div class="inBox">
					<div class="imgArea">
						<img src="/resources/images/buildingTip/img05-7.png" alt="" />
						<img style="width:70%;" src="/resources/images/buildingTip/img05-7m.png" alt="" />
					</div>
					<div class="txtArea">
						<div>
							<h4 class="depth">메인 > 건물 상세 > 프로젝트</h4>
							<h4>해당 건물의 프로젝트 목록을 표시하는 화면입니다.</h4>
							<dl>
								<dt><span>1.</span> 프로젝트 목록</dt>
								<dd>- 해당 건물에 등록되어 있는 프로젝트 목록을 표시합니다.</dd>
							</dl>
							<dl>
								<dt><span>2.</span> 버튼 영역</dt>
								<dd>- 프로젝트 등록 화면으로 이동합니다.</dd>
							</dl>
							<dl>
								<dt><span>3.</span> 프로젝트</dt>
								<dd>- 프로젝트가 진행 중일때는 하늘색아이콘 끝난 프로젝트라면 회색으로 표시합니다.</dd>
								<dd>- 프로젝트 명을 표시합니다.</dd>
								<dd>- 해당 프로젝트에 등록된 메모 개수를 표시합니다.</dd>
							</dl>
							<dl>
								<dt><span>4.</span> 수정 버튼 영역</dt>
								<dd>- 해당 프로젝트에 대한 수정 화면으로 이동합니다.</dd>
							</dl>
							<dl>
								<dt><span>5.</span> 클릭 영역</dt>
								<dd>- 해당 구역을 클릭하여 프로젝트에 대한 메모 목록 화면으로 이동합니다.</dd>
							</dl>
						</div>
					</div>
				</div>
			</div>
			<div class="manualArea">
				<h3>프로젝트 등록</h3>
				<div class="inBox">
					<div class="imgArea">
						<img src="/resources/images/buildingTip/img05-8.png" alt="" />
						<img style="width:70%;" src="/resources/images/buildingTip/img05-8m.png" alt="" />
					</div>
					<div class="txtArea">
						<div>
							<h4 class="depth">메인 > 건물 상세 > 프로젝트 > 등록</h4>
							<h4>해당 건물의 프로젝트를 등록하는 화면입니다.</h4>
							<dl>
								<dt><span>1.</span> 프로젝트 명</dt>
								<dd>- 프로젝트 명을 입력합니다.</dd>
							</dl>
							<dl>
								<dt><span>2.</span> 시작일자</dt>
								<dd>- 프로젝트의 시작 일자를 입력합니다.</dd>
								<dd>- 클릭 시, 2-1과 같이 날짜를 선택할 수 있는 달력이 생성됩니다.</dd>
							</dl>
							<dl>
								<dt><span>3.</span> 종료일자</dt>
								<dd>- 프로젝트의 종료 일자를 입력합니다.</dd>
								<dd>- 클릭 시, 2-1과 같이 날짜를 선택할 수 있는 달력이 생성됩니다.</dd>
							</dl>
							<dl>
								<dt><span>4.</span> 버튼 영역</dt>
								<dd>- 등록: 해당 버튼을 눌러 프로젝트를 등록합니다.</dd>
							</dl>
						</div>
					</div>
				</div>
			</div>
			<div class="manualArea">
				<h3>프로젝트 수정</h3>
				<div class="inBox">
					<div class="imgArea">
						<img src="/resources/images/buildingTip/img05-9.png" alt="" />
						<img style="width:70%;" src="/resources/images/buildingTip/img05-9m.png" alt="" />
					</div>
					<div class="txtArea">
						<div>
							<h4 class="depth">메인 > 건물 상세 > 프로젝트 > 수정</h4>
							<h4>해당 건물의 프로젝트를 수정하는 화면입니다.</h4>
							<dl>
								<dt><span>1.</span> 프로젝트 명</dt>
								<dd>- 프로젝트 명을 입력합니다.</dd>
							</dl>
							<dl>
								<dt><span>2.</span> 시작일자</dt>
								<dd>- 프로젝트의 시작 일자를 입력합니다.</dd>
								<dd>- 클릭 시, 2-1과 같이 날짜를 선택할 수 있는 달력이 생성됩니다.</dd>
							</dl>
							<dl>
								<dt><span>3.</span> 종료일자</dt>
								<dd>- 프로젝트의 종료 일자를 입력합니다.</dd>
								<dd>- 클릭 시, 2-1과 같이 날짜를 선택할 수 있는 달력이 생성됩니다.</dd>
							</dl>
							<dl>
								<dt><span>4.</span> 버튼 영역</dt>
								<dd>- 삭제: 해당 버튼을 눌러 프로젝트를 삭제합니다.</dd>
								<dd>- 수정: 해당 버튼을 눌러 프로젝트를 수정합니다.</dd>
							</dl>
						</div>
					</div>
				</div>
			</div>
			<div class="manualArea">
				<h3>프로젝트 메모 목록</h3>
				<div class="inBox">
					<div class="imgArea">
						<img src="/resources/images/buildingTip/img05-10.png" alt="" />
						<img style="width:70%;" src="/resources/images/buildingTip/img05-10m.png" alt="" />
					</div>
					<div class="txtArea">
						<div>
							<h4 class="depth">메인 > 건물 상세 > 프로젝트 > 메모 목록</h4>
							<h4>해당 건물의 프로젝트에 대한 메모 목록을 표시하는 화면입니다.</h4>
							<dl>
								<dt><span>1.</span> 프로젝트 명</dt>
								<dd>- 해당 프로젝트에 대한 프로젝트 명을 표시합니다.</dd>
							</dl>
							<dl>
								<dt><span>2.</span> 글 목록</dt>
								<dd>- 해당 프로젝트에 등록된 메모 목록을 보여줍니다.</dd>
							</dl>
						</div>
					</div>
				</div>
			</div>
			<div class="manualArea">
				<h3>입주사 관리</h3>
				<div class="inBox">
					<div class="imgArea">
						<img src="/resources/images/buildingTip/img05-11.png" alt="" />
						<img style="width:70%;" src="/resources/images/buildingTip/img05-11m.png" alt="" />
					</div>
					<div class="txtArea">
						<div>
							<h4 class="depth">메인 > 건물 상세 > 입주사</h4>
							<h4>해당 건물의 입주사 목록을 표시하는 화면입니다.</h4>
							<dl>
								<dt><span>1.</span> 입주사 목록</dt>
								<dd>- 입주사 목록을 표시합니다.</dd>
								<dd>- 입주사명, 입주사 대표명, 입주사 연락처의 정보를 표시합니다.</dd>
							</dl>
							<dl>
								<dt><span>2.</span> 버튼 영역</dt>
								<dd>- 입주사 등록: 해당 버튼을 눌러 입주사 등록화면으로 이동합니다.</dd>
							</dl>
							<dl>
								<dt><span>3.</span> 클릭 영역</dt>
								<dd>- 입주사를 수정할 수 있는 화면으로 이동합니다.</dd>
							</dl>
						</div>
					</div>
				</div>
			</div>
			<div class="manualArea">
				<h3>입주사 등록</h3>
				<div class="inBox">
					<div class="imgArea">
						<img src="/resources/images/buildingTip/img05-12.png" alt="" />
						<img style="width:70%;" src="/resources/images/buildingTip/img05-12m.png" alt="" />
					</div>
					<div class="txtArea">
						<div>
							<h4 class="depth">메인 > 건물 상세 > 입주사 > 등록</h4>
							<h4>해당 건물의 입주사를 등록하는 화면입니다.</h4>
							<dl>
								<dt><span>1.</span> 입주사 명</dt>
								<dd>- 필수값으로, 입주사 명을 입력합니다.</dd>
							</dl>
							<dl>
								<dt><span>2.</span> 관리자 명</dt>
								<dd>- 관리자명을 입력합니다.</dd>
							</dl>
							<dl>
								<dt><span>3.</span> 관리자 전화번호</dt>
								<dd>- 관리자 전화번호를 입력합니다</dd>
							</dl>
							<dl>
								<dt><span>4.</span> 버튼 영역</dt>
								<dd>- 취소: 입주사 생성을 취소하고 돌아갑니다.</dd>
								<dd>- 저장: 입주사를 생성합니다.</dd>
							</dl>
						</div>
					</div>
				</div>
			</div>
			<div class="manualArea">
				<h3>입주사 수정</h3>
				<div class="inBox">
					<div class="imgArea">
						<img src="/resources/images/buildingTip/img05-13.png" alt="" />
						<img style="width:70%;" src="/resources/images/buildingTip/img05-13m.png" alt="" />
					</div>
					<div class="txtArea">
						<div>
							<h4 class="depth">메인 > 건물 상세 > 입주사 > 수정</h4>
							<h4>해당 건물의 입주사를 수정하는 화면입니다.</h4>
							<dl>
								<dt><span>1.</span> 입주사 명</dt>
								<dd>- 필수값으로, 입주사 명을 입력합니다.</dd>
							</dl>
							<dl>
								<dt><span>2.</span> 관리자 명</dt>
								<dd>- 관리자명을 입력합니다.</dd>
							</dl>
							<dl>
								<dt><span>3.</span> 관리자 전화번호</dt>
								<dd>- 관리자 전화번호를 입력합니다</dd>
							</dl>
							<dl>
								<dt><span>4.</span> 버튼 영역</dt>
								<dd>- 취소: 입주사 생성을 취소하고 돌아갑니다.</dd>
								<dd>- 삭제: 해당 입주사를 삭제합니다.</dd>
								<dd>- 저장: 입주사 정보를 수정합니다.</dd>
							</dl>
						</div>
					</div>
				</div>
			</div>
			<div class="manualArea">
				<h3>층별 상세</h3>
				<div class="inBox">
					<div class="imgArea">
						<img src="/resources/images/buildingTip/img06-1.png" alt="" />
						<img style="width:70%;" src="/resources/images/buildingTip/img06-1m.png" alt="" />
					</div>
					<div class="txtArea">
						<div>
							<h4 class="depth">메인 > 건물 상세 > 층별 상세</h4>
							<h4>층별 상세 정보를 표시하는 화면입니다.</h4>
							<dl>
								<dt><span>1.</span> 현재 위치 및 버튼 영역</dt>
								<dd>- 현재 층의 위치 정보와 전체 층 수에서 현재 층 수를 표시합니다.</dd>
								<dd>
									- 좌우버튼을 선택 시 이전 층과 다음 층으로 이동하며, 이동할 층이 없는 경우는 비활성화 표시됩니다.
								</dd>
							</dl>
							<dl>
								<dt><span>2.</span> 도면 탭 영역</dt>
								<dd>- 기본편명도, 전기도면, 소방도면, 위생/배관 탭 영역을 표시합니다.</dd>
								<dd>- 해당 도면에 도면이 등록되어 있지 않다면 빨간 아이콘으로 표시합니다.</dd>
							</dl>
							<dl>
								<dt><span>3.</span> 도면 영역</dt>
								<dd>- 도면등록 버튼을 선택시 “아래층 도면 복사, 파일 선택” 팝업이 화면(3-1)이 노출됩니다.</dd>
								<dd>- 도면이 등록된 경우 도면 등록 버튼이 표시되지 않습니다.</dd>
								<dd>
									- 아래층 도면 복사는 자신이 도면이 등록되어 있지 않은 상태에서 지상층은 바로 아래층, 지하층은 바로
									위층의 모든 도면을 복사합니다.
								</dd>
								<dd>- 아래층 도면 복사는 지상 2층와 지하 2층 부터 가능합니다.</dd>
							</dl>
							<dl>
								<dt><span>4.</span> 최신글</dt>
								<dd>- 해당 층에 등록된 메모 목록을 최신순으로 표시됩니다.</dd>
							</dl>
							<dl>
								<dt><span>5.</span> 플로팅 메뉴</dt>
								<dd>- 메모쓰기: 클릭 시 메모하기 화면으로 이동합니다.</dd>
							</dl>
						</div>
					</div>
				</div>
			</div>
			<div class="manualArea">
				<h3>층별 상세(도면)</h3>
				<div class="inBox">
					<div class="imgArea">
						<img src="/resources/images/buildingTip/img06-2.png" alt="" />
						<img style="width:70%;" src="/resources/images/buildingTip/img06-2m.png" alt="" />
					</div>
					<div class="txtArea">
						<div>
							<h4 class="depth">메인 > 건물 상세 > 층별 상세</h4>
							<h4>층별 상세 정보를 표시하는 화면입니다.</h4>
							<dl>
								<dt><span>1.</span> 도면 탭 영역</dt>
								<dd>- 기본편명도, 전기도면, 소방도면, 위생/배관 탭 영역을 표시합니다.</dd>
								<dd>- 도면이 등록된 경우 도면 이미지가 표시되고 빨간 아이콘은 사라집니다.</dd>
							</dl>
							<dl>
								<dt><span>2.</span> 도면 영역</dt>
								<dd>- 도면 이미지와 실 정보, 오브제핀을 표시합니다.</dd>
							</dl>
							<dl>
								<dt><span>3.</span> 실별 정보</dt>
								<dd>- 실 영역, 실 명을 표시합니다.</dd>
								<dd>- 편집 버튼을 클릭 시 실별 상세 화면으로 이동합니다.</dd>
							</dl>
							<dl>
								<dt><span>4.</span> 설정</dt>
								<dd>- 클릭 시 현재 도면의 도면 에디터 화면으로 이동합니다.</dd>
							</dl>
							<dl>
								<dt><span>5.</span> 버튼 영역</dt>
								<dd>- 오브제핀설정: 클릭 시 현재 층을 대상으로 오브제 핀을 설정 할 수 있습니다.</dd>
							</dl>
						</div>
					</div>
				</div>
			</div>
			<div class="manualArea">
				<h3>층별 상세(메모)</h3>
				<div class="inBox">
					<div class="imgArea">
						<img src="/resources/images/buildingTip/img06-3.png" alt="" />
						<img style="width:70%;" src="/resources/images/buildingTip/img06-3m.png" alt="" />
					</div>
					<div class="txtArea">
						<div>
							<h4 class="depth">메인 > 건물 상세 > 층별 상세(메모 목록)</h4>
							<h4>층별 상세 정보를 표시하는 화면입니다.</h4>
							<dl>
								<dt><span>1.</span> 메모 목록</dt>
								<dd>- 층별로 등록된 메모 목록을 등록 순으로 표시합니다.</dd>
							</dl>
							<dl>
								<dt><span>2.</span> 메모 조회</dt>
								<dd>- 메모 별 정보를 표시합니다.</dd>
								<dd>
									- 등록자 프로필 이미지, 닉네임, 등록일 시, 메모 내용, 이미지, 댓글 수, 조회수 정보를 표시합니다.
								</dd>
							</dl>
							<dl>
								<dt><span>3.</span> 선택 영역</dt>
								<dd>- 선택영역을 클릭하면 해당 메모의 상세 화면으로 이동합니다.</dd>
							</dl>
						</div>
					</div>
				</div>
			</div>
			<div class="manualArea">
				<h3>층별 상세(오브제핀등록)</h3>
				<div class="inBox">
					<div class="imgArea">
						<img src="/resources/images/buildingTip/img06-4.png" alt="" />
						<img style="width:70%;" src="/resources/images/buildingTip/img06-4m.png" alt="" />
					</div>
					<div class="txtArea">
						<div>
							<h4 class="depth">메인 > 건물 상세 > 층별 상세 > 오브제핀등록</h4>
							<h4>층별 오브제핀을 설정하는 화면입니다.</h4>
							<dl>
								<dt><span>1.</span> 버튼 영역</dt>
								<dd>- 오브제 영역추가: 해당 버튼을 눌러 드래그하여 오브제 영역을 추가 할 수 있습니다.</dd>
							</dl>
							<dl>
								<dt><span>2.</span> 오브제 핀 영역</dt>
								<dd>- 오브제 핀 영역을 생성하였다면, 2와 같이 보이고 클릭하여 위치를 이동할 수 있습니다.</dd>
							</dl>
							<dl>
								<dt><span>3.</span> 휴지통</dt>
								<dd>- 삭제 버튼: 해당 오브제핀 영역이 클릭되어있다면 생성되고 해당 오브제를 삭제 할 수 있습니다.</dd>
							</dl>
							<dl>
								<dt><span>4.</span> 버튼 영역</dt>
								<dd>- 취소: 취소 버튼을 눌러 작업을 취소하여 층별 상세 화면으로 이동할 수 있습니다.</dd>
								<dd>- 저장: 오브제핀 영역 추가 작업을 마치고 저장합니다.</dd>
							</dl>
						</div>
					</div>
				</div>
			</div>
			<div class="manualArea">
				<h3>도면 에디터</h3>
				<div class="inBox">
					<div class="imgArea">
						<img src="/resources/images/buildingTip/img07-1.png" alt="" />
						<img style="width:100%;" src="/resources/images/buildingTip/img07-1m.png" alt="" />
					</div>
					<div class="txtArea">
						<div>
							<h4 class="depth">메인 > 건물 상세 > 층별 상세 > 도면 에디터</h4>
							<h4>층별 선택한 도면에 대한 실별 편집기능을 제공하는 화면입니다.</h4>
							<dl>
								<dt><span>1.</span> 에디터 아이콘 영역</dt>
								<dd>- 선택 아이콘, 편집 아이콘 및 휴지통 아이콘을 노출합니다.</dd>
								<dd>
									- 선택 아이콘: 사각형, 원, 다각형을 그릴 수 있으며, 다각형인 경우는 그리는 중에 완료 버튼(1-1)이
									표시되고, 그리기를 마칠려면 완표 버튼을 클릭합니다.
								</dd>
								<dd>
									- 그리기가 완료되며 실별, 사용자 명을 입력받는 팝업창(3-1)이 표시되고 실별, 입주사를 선택합니다.
								</dd>
								<dd>- 편집 아이콘으로 기존 실별 영역을 수정, 삭제할 수 있습니다.</dd>
								<dd>- 휴지통 아이콘은 현재 도면을 삭제할 수 있습니다.</dd>
							</dl>
							<dl>
								<dt><span>2.</span> 도면 영역</dt>
								<dd>- 도면 이미지와 실영역 정보를 표시합니다.</dd>
								<dd>- 도면 영역 배경을 마우스로 드래그할 경우 화면 위치를 이동할 수 있습니다.</dd>
							</dl>
							<dl>
								<dt><span>3.</span> 도면 이미지</dt>
								<dd>- 도면 이미지가 화면 가운데에 화면 크기에 맞게 스케일 조정해서 표시됩니다.</dd>
							</dl>
							<dl>
								<dt><span>4.</span> 실별 영역</dt>
								<dd>- 실별 영역을 그리기 모양에 따라 화면에 표시됩니다.</dd>
								<dd>
									- 편집를 위해 선택한 실별 영역(4-1)은 각 모서리에 작은 사각형이 표시되고, 해당 사각형을 이용해 모양을
									편집할 수 있습니다.
								</dd>
								<dd>- 선택된 실별 영역 내에서 마우스를 드래그할 경우 실별 영역을 이동할 수 있습니다.</dd>
								<dd>
									- 선택된 실별 영역에 대해 수정 버튼을 선택할 경우 실별, 사용자 명을 수정할 수 있는 팝업창(3-1)이
									표시됩니다.
								</dd>
							</dl>
							<dl>
								<dt><span>5.</span> 줌 영역</dt>
								<dd>- 도면 화면을 줌인, 줌아웃 할 수 있는 버튼을 제공합니다.</dd>
							</dl>
							<dl>
								<dt><span>6.</span> 버튼 영역</dt>
								<dd>- 취소: 현재 작업 중이 내용을 취소하고 층별 상세 화면으로 이동합니다.</dd>
								<dd>- 저장: 현재 작업 내용을 저장하고, 층별 상세 화면으로 이동합니다.</dd>
							</dl>
						</div>
					</div>
				</div>
			</div>
			<div class="manualArea">
				<h3>실별 상세</h3>
				<div class="inBox">
					<div class="imgArea">
						<img src="/resources/images/buildingTip/img08-1.png" alt="" />
						<img style="width:90%;" src="/resources/images/buildingTip/img08-1m.png" alt="" />
					</div>
					<div class="txtArea">
						<div>
							<h4 class="depth">메인 > 건물 상세 > 층별 상세 > 실별 상세</h4>
							<h4>층별 상세에서 실별 상세 정보를 표시하는 화면입니다.</h4>
							<dl>
								<dt><span>1.</span> 네비게이션 영역</dt>
								<dd>- 현재 실별에 대한 메뉴 위치 정보가 표시됩니다.</dd>
								<dd>- 각 메뉴 단계를 클릭하면 해당 화면으로 이동합니다.</dd>
							</dl>
							<dl>
								<dt><span>2.</span> 도면 영역</dt>
								<dd>- 도면과 같이 실별 영역을 확대 표시합니다.</dd>
							</dl>
							<dl>
								<dt><span>3.</span> 실별 영역</dt>
								<dd>- 실별 영역을 옅은 파랑색 영역으로 표시합니다.</dd>
							</dl>
							<dl>
								<dt><span>4.</span> 오브제핀 영역</dt>
								<dd>- 오브제핀 영역을 옅은 빨간색 영역으로 표시하고 가운데 핀을 위치합니다.</dd>
							</dl>
							<dl>
								<dt><span>5.</span> 탭 영역</dt>
								<dd>- 실별에 등록된 오브제 목록과 글목록을 표시합니다.</dd>
							</dl>
							<dl>
								<dt><span>6.</span> 글목록 카테고리</dt>
								<dd>- 오브제, 글 목록으로 이루어진 카테고리 중에서 글 목록 카테고리를 선택했을 경우,</dd>
								<dd>해당 실과 오브제 영역에 대한 메모 글 목록이 표시됩니다.</dd>
							</dl>
							<dl>
								<dt><span>7.</span> 글목록 영역</dt>
								<dd>
									- 글 목록 영역에는 메모 대상 위치, 작성자 프로필 이미지, 메모내용, 등록된 이미지, 프로젝트, 스케줄이
									표시됩니다.
								</dd>
							</dl>
							<dl>
								<dt><span>8.</span> 플로팅 메뉴</dt>
								<dd>- 메모하기 버튼으로 클릭시 메모하기 화면으로 이동합니다.</dd>
							</dl>
							<dl>
								<dt><span>9.</span> 설정</dt>
								<dd>- 실별에 대한 오브제핀 에디터 화면으로 이동합니다.</dd>
							</dl>
							<dl>
								<dt><span>10.</span> 도면영역</dt>
								<dd>- 도면과 같이 실별 영역을 확대 표시합니다.</dd>
							</dl>
						</div>
					</div>
				</div>
			</div>
			<div class="manualArea">
				<h3>실별 상세</h3>
				<div class="inBox">
					<div class="imgArea">
						<img src="/resources/images/buildingTip/img08-2.png" alt="" />
						<img style="width:70%;" src="/resources/images/buildingTip/img08-2m.png" alt="" />
					</div>
					<div class="txtArea">
						<div>
							<h4 class="depth">메인 > 건물 상세 > 층별 상세 > 실별 상세</h4>
							<h4>층별 상세에서 실 별 상세 정보를 표시하는 화면입니다.</h4>
							<dl>
								<dt><span>1.</span> 오브제핀 정보</dt>
								<dd>
									- 등록된 오브제 핀정보를 표시합니다.
								</dd>
							</dl>
							<dl>
								<dt><span>2.</span> 글쓰기</dt>
								<dd>
									- 컨텍스트 메뉴(2-1)가 표시되고 활성화 또는 비활성 선택할 수 있습니다.
								</dd>
								<dd>
									- 비활성화 : 활성화되어 있는 오브제핀을 비활성화합니다.
								</dd>
								<dd>
									- 실이동 : 해당 층에 등록되어 있는 다른 실(방)으로 이동합니다.
								</dd>
								<dd>
									- 삭제 : 활성화되어 있는 오브제핀을 삭제합니다.
								</dd>
							</dl>
							<dl>
								<dt><span>3.</span> 글쓰기</dt>
								<dd>- 해당 오브제핀에 대한 메모를 등록하는 화면으로 이동합니다.</dd>
							</dl>
						</div>
					</div>
				</div>
			</div>
			<div class="manualArea">
				<h3>실별 상세</h3>
				<div class="inBox">
					<div class="imgArea">
						<img src="/resources/images/buildingTip/img08-3.png" alt="" />
						<img style="width:70%;" src="/resources/images/buildingTip/img08-3m.png" alt="" />
					</div>
					<div class="txtArea">
						<div>
							<h4 class="depth">메인 > 건물 상세 > 층별 상세 > 실별 상세 > 실이동 안내</h4>
							<h4>층별 상세에서 실 별 상세 정보를 표시하는 화면입니다.</h4>
							<dl>
								<dt><span>1.</span> 현재 실 정보</dt>
								<dd>
									- 현재 위치한 실(방) 정보를 표시합니다.
								</dd>
							</dl>
							<dl>
								<dt><span>2.</span> 이동가능한 실 메뉴</dt>
								<dd>
									- 컨텍스트 메뉴(2-1)가 표시되고 실(방)을 선택할 수 있습니다.
								</dd>
								<dd>
									- 비활성화 : 활성화되어 있는 오브제핀을 비활성화합니다.
								</dd>
							</dl>
							<dl>
								<dt><span>3.</span> 버튼</dt>
								<dd>- 선택 : 해당 층에 등록되어 있는 다른 실(방)으로 이동합니다.</dd>
								<dd>- 취소 : 실(방) 이동을 취소 합니다.</dd>
							</dl>
						</div>
					</div>
				</div>
			</div>
			<div class="manualArea">
				<h3>오브제 영역 추가</h3>
				<div class="inBox">
					<div class="imgArea">
						<img src="/resources/images/buildingTip/img09-1.png" alt="" />
						<img style="width:70%;" src="/resources/images/buildingTip/img09-1m.png" alt="" />
					</div>
					<div class="txtArea">
						<div>
							<h4 class="depth">메인 > 건물 상세 > 층별 상세 > 실별 상세 > 오브제영역 추가</h4>
							<h4>오브제 영역을 등록, 수정하는 화면입니다.</h4>
							<dl>
								<dt><span>1.</span> 오브제 영역 추가</dt>
								<dd>- 오브제 영역 버튼을 눌러, 오브제 영역을 추가 할 수 있습니다.</dd>
								<dd>- 선택 시 (1) 아래와 같이 테두리가 생기며, 오브제 영역 추가모드가 활성화 됩니다.</dd>
								<dd>- 마우스로 드래그하여 오브제 영역을 생성 할 수 있습니다.</dd>
							</dl>
							<dl>
								<dt><span>2.</span> 오브제 영역</dt>
								<dd>
									- 등록된 오브제 영역으로 선택하면 휴지통 버튼(3)이 등장하며, 클릭 시 오브제 영역을 비활성화 할 수
									있습니다.
								</dd>
							</dl>
							<dl>
								<dt><span>3.</span> 오브제 비활성화 버튼</dt>
								<dd>
									- 오브제 영역 클릭 시 오브제 비활성화 버튼이 생성되며, 비활성화 버튼 선택된 오브제 영역을 비활성화 할
									수 있습니다.
								</dd>
							</dl>
							<dl>
								<dt><span>4.</span> 버튼</dt>
								<dd>- 취소 버튼: 현재 오브제 영역 추가 작업을 취소합니다.</dd>
								<dd>- 확인 버튼: 현재 오브제 영역 추가 작업을 완료합니다.</dd>
							</dl>
							<dl>
								<dt><span>5.</span> 버튼</dt>
								<dd>- 축소 확대 버튼 : 등록된 도면영역을 확대하거나 축소해서 이미지를 보여줄 수 있습니다.</dd>
							</dl>
						</div>
					</div>
				</div>
			</div>
			<div class="manualArea">
				<h3>오브제 목록</h3>
				<div class="inBox">
					<div class="imgArea">
						<img src="/resources/images/buildingTip/img09-2.png" alt="" />
						<img style="width:100%;" src="/resources/images/buildingTip/img09-2m.png" alt="" />
					</div>
					<div class="txtArea">
						<div>
							<h4 class="depth">메인 > 건물 상세 > 층별 상세 > 실별 상세 > 오브제영역 추가 > 오브제 목록</h4>
							<h4>추가할 오브제핀 을 선택하거나 기존 오브제를 관리하는 화면입니다.</h4>
							<dl>
								<dt><span>1.</span> 오브제 카테고리</dt>
								<dd>- 6가지의 카테고리가 존재하며, 인싸이트노트에서 제공하는 기본오브제가 존재합니다.</dd>
							</dl>
							<dl>
								<dt><span>2.</span> 오브제 목록</dt>
								<dd>- 오브제는 2가지 종류로 인싸이트 노트에서 제공한 오브제와 사용자가 등록한 오브제가 있습니다.</dd>
								<dd>- 사용자가 등록한 오브제는 우측상단의 x버튼으로 삭제 할 수 있습니다.</dd>
							</dl>
							<dl>
								<dt><span>3.</span> 사용자 오브제</dt>
								<dd>- 사용자 오브제는 사용자가 직접 등록한 오브제입니다.</dd>
								<dd>- 사용자 오브제 삭제 시, (4-1) 해당 오브제를 삭제 할 수 있습니다.</dd>
								<dd>- 사용자 오브제 삭제 시, (4-2) 해당 오브제로 지정된 오브제 영역도 같이 삭제됩니다.</dd>
							</dl>
							<dl>
								<dt><span>4.</span> 추가하기</dt>
								<dd>
									- 오브제 추가 버튼으로 오브제 등록을 할 수 있습니다.
								</dd>
							</dl>
							<dl>
								<dt><span>5.</span> 오브제 등록</dt>
								<dd>
									- 추가하기 버튼 클릭 시 팝업되며, 오브제의 이름과 이미지를 설정하여 사용자 오브제를 생성 할 수
									있습니다.
								</dd>
							</dl>
						</div>
					</div>
				</div>
			</div>
			<div class="manualArea">
				<h3>오브제 영역 메모(목록)</h3>
				<div class="inBox">
					<div class="imgArea">
						<img src="/resources/images/buildingTip/img09-3.png" alt="" />
						<img style="width:70%;" src="/resources/images/buildingTip/img09-3m.png" alt="" />
					</div>
					<div class="txtArea">
						<div>
							<h4 class="depth">메인 > 건물 상세 > 층별 상세 > 실별 상세 > 오브제 > 메모 목록</h4>
							<h4>대상 오브제 영역의 메모 목록을 확인 할 수 있는 화면입니다.</h4>
							<dl>
								<dt><span>1.</span> 메모 등록</dt>
								<dd>- 대상 오브제 영역의 메모 등록 화면으로 이동합니다.</dd>
							</dl>
							<dl>
								<dt><span>2.</span> 메모 목록</dt>
								<dd>- 대상 오브제 영역의 메모 목록을 확인할 수 있으며, 클릭 시 메모 상세 화면으로 이동합니다.</dd>
							</dl>
						</div>
					</div>
				</div>
			</div>
			<div class="manualArea">
				<h3>메모(등록)</h3>
				<div class="inBox">
					<div class="imgArea">
						<img src="/resources/images/buildingTip/img12-1.png" alt="" />
						<img style="width:70%;" src="/resources/images/buildingTip/img12-1m.png" alt="" />
						<img style="width:70%;" src="/resources/images/buildingTip/img12-1-1m.png" alt="" />
					</div>
					<div class="txtArea">
						<div>
							<h4 class="depth">메인 > 건물 상세 > 메모 등록</h4>
							<h4>오브제 영역에 대한 메모를 등록하는 화면 입니다.</h4>
							<dl>
								<dt><span>1.</span> 메모 내용</dt>
								<dd>- 메모 내용을 입력할 수 있습니다.</dd>
							</dl>
							<dl>
								<dt><span>2.</span> 사용위치</dt>
								<dd>- 3번 위치등록 추가하기를 통해 선택 된 위치(등록될)를 보여줍니다.</dd>
							</dl>
							<dl>
								<dt><span>3.</span> 위치등록</dt>
								<dd>- 메모가 등록할 위치를 선택하는 팝업창이 보여집니다.</dd>
							</dl>
							<dl>
								<dt><span>3-1.</span> 위치등록 선택</dt>
								<dd>- 메모가 등록될 위치를 선택하는 팝업창이 보여집니다.</dd>
							</dl>
						</div>
					</div>
				</div>
			</div>

			<div class="manualArea">
				<h3>메모(등록)</h3>
				<div class="inBox">
					<div class="imgArea">
						<img src="/resources/images/buildingTip/img12-2.png" alt="" />
						<img style="width:70%;" src="/resources/images/buildingTip/img12-2m.png" alt="" />
						<img style="width:70%;" src="/resources/images/buildingTip/img12-2-1m.png" alt="" />
					</div>
					<div class="txtArea">
						<div>
							<h4 class="depth">메인 > 건물 상세 > 메모 등록</h4>
							<h4>오브제 영역에 대한 메모를 등록하는 화면 입니다.</h4>
							<dl>
								<dt><span>4.</span> 스케줄</dt>
								<dd>- 스케줄 팝업창을 통해 스케줄 일정을 선택한 값이 보여집니다.</dd>
							</dl>
							<dl>
								<dt><span>4-1.</span> 스케줄 등록</dt>
								<dd>- 스케줄 등록이 가능한 팝업창 입니다.</dd>
								<dd>- 스케줄명, 주기종류(년간,월간,주간) 시작일,종료일 선택가능합니다.</dd>
							</dl>
							<dl>
								<dt><span>4-2</span> 스케줄 등록</dt>
								<dd>- 메모에 등록된 스케줄 형태 입니다.</dd>
							</dl>
						</div>
					</div>
				</div>
			</div>

			<div class="manualArea">
				<h3>메모(등록)</h3>
				<div class="inBox">
					<div class="imgArea">
						<img src="/resources/images/buildingTip/img12-3.png" alt="" />
						<img style="width:70%;" src="/resources/images/buildingTip/img12-3m.png" alt="" />
						<img style="width:70%;" src="/resources/images/buildingTip/img12-3-1m.png" alt="" />
					</div>
					<div class="txtArea">
						<div>
							<h4 class="depth">메인 > 건물 상세 > 메모 등록</h4>
							<h4>메모를 등록하는 화면 입니다.</h4>
							<dl>
								<dt><span>5.</span> 프로젝트</dt>
								<dd>- 스케줄 팝업창을 통해 스케줄 일정을 선택한 값이 보여집니다.</dd>
							</dl>
							<dl>
								<dt><span>5-1.</span> 프로젝트 리스트</dt>
								<dd>- 프로젝트 선택이 가능합니다.</dd>
								<dd>
									- 지난 프로젝트를 선택하고자 하는경우 전체 버튼을 눌러 이전에 등록한 프로젝트가 보여지게 해야 합니다.
								</dd>
								<dd>- 첫번째 체크 박스를 통해 프로젝트 입력 창을 불러옵니다.</dd>
								<dd>-두번째 체크 박스를 통해 현재 진행중 프로젝트와, 모든 프로젝트를 보여주게 합니다.</dd>
							</dl>
							<dl>
								<dt><span>5-2.</span> 프로젝트 등록</dt>
								<dd>- 메모에 등록된 스케줄 형태 입니다.</dd>
							</dl>
							<dl>
								<dt><span>6.</span> 이미지</dt>
								<dd>- 이미지 업로드 버튼을 클릭해 업로드할 이미지를 추가할 수 있습니다.</dd>
								<dd>- 추가된 이미지는 미리보기 이미지로 표시됩니다.</dd>
								<dd>
									- 이미지 원본 체크를 하면 원본으로 업로드가 가능하며 기본으로는 1200px 가 넘어가는경우 1200으로
									조정합니다.
								</dd>
							</dl>
							<dl>
								<dt><span>7.</span> 첨부파일</dt>
								<dd>- 첨부파일 업로드 버튼을 클릭해서 첨부할 파일을 추가할 수 있습니다.</dd>
								<dd>- 첨부타 파일은 목록 형태로 표시됩니다.</dd>
							</dl>
							<dl>
								<dt><span>8.</span> 버튼 영역</dt>
								<dd>- 저장하기: 입력된 메모을 등록하고 층별 상세 화면으로 이동합니다.</dd>
							</dl>
						</div>
					</div>
				</div>
			</div>

			<div class="manualArea">
				<h3>메모(상세)</h3>
				<div class="inBox">
					<div class="imgArea">
						<img src="/resources/images/buildingTip/img12-4.png" alt="" />
						<img style="width:70%;" src="/resources/images/buildingTip/img12-4m.png" alt="" />
						<img style="width:70%;" src="/resources/images/buildingTip/img12-4-1m.png" alt="" />
					</div>
					<div class="txtArea">
						<div>
							<h4 class="depth">메인 > 건물 상세 > 메모 리스트 > 메모 상세</h4>
							<h4>선택한 메모 상세 정보를 표시하는 화면 입니다.</h4>
							<dl>
								<dt><span>1.</span> 네비게이션 영역</dt>
								<dd>- 메모가 등록된 메뉴 위치 정보가 표시됩니다.</dd>
								<dd>- 각 메뉴 단계를 클릭하면 해당 화면으로 이동합니다.</dd>
							</dl>
							<dl>
								<dt><span>2.</span> 등록 영역</dt>
								<dd>- 메모를 작성한 작성자의 닉네임과 프로필 이미지, 그리고 등록일시를 표시합니다.</dd>
							</dl>
							<dl>
								<dt><span>3.</span> 프로젝트 영역</dt>
								<dd>- 메모에 등록된 프로젝트 내용과 기간을 표시합니다.</dd>
								<dd>- 프로젝트 영역을 클릭하면 프로젝트 리스트로 이동합니다.</dd>
							</dl>
							<dl>
								<dt><span>4.</span> 스케줄 영역</dt>
								<dd>- 메모에 등록된 스케줄 내용과 상태를 표시합니다.</dd>
							</dl>
							<dl>
								<dt><span>5.</span> 내용 영역</dt>
								<dd>- 메모 내용을 표시합니다.</dd>
							</dl>
							<dl>
								<dt><span>6.</span> 이미지 영역</dt>
								<dd>- 메모에 등록된 이미지를 좌우로 나열합니다.</dd>
								<dd>- 이미지를 클릭하면 팝업 형태로 이미지를 확대해서 보여줍니다.</dd>
							</dl>
							<dl>
								<dt><span>7.</span> 첨부파일 영역</dt>
								<dd>- 첨부된 파일을 상하 목록으로 표시합니다.</dd>
							</dl>
							<dl>
								<dt><span>8.</span> 버튼 영역</dt>
								<dd>- 수정 버튼: 클릭하면 메모 수정화면으로 이동합니다.</dd>
								<dd>- 삭제 버튼: 현재 메모를 삭제합니다.</dd>
							</dl>
							<dl>
								<dt><span>9.</span> 아이콘 영역</dt>
								<dd>- 메모 댓글 수와 조회수를 노출합니다.</dd>
							</dl>
							<dl>
								<dt><span>10.</span> 댓글 입력 영역</dt>
								<dd>- 댓글을 입력할 수 있습니다.</dd>
							</dl>
							<dl>
								<dt><span>11.</span> 댓글 등록 버튼 영역</dt>
								<dd>- 등록하기 버튼을 클릭하면 입력된 댓글이 등록됩니다.</dd>
							</dl>
							<dl>
								<dt><span>12.</span> 댓글 목록</dt>
								<dd>- 현재 메모에 등록된 메모를 등록 순으로 표시됩니다.</dd>
							</dl>
							<dl>
								<dt><span>13.</span> 댓글 수정 버튼 영역</dt>
								<dd>- 해당 댓글을 수정하거나 삭제할 수 있습니다.</dd>
							</dl>
						</div>
					</div>
				</div>
			</div>

			<div class="manualArea">
				<h3>메모(수정)</h3>
				<div class="inBox">
					<div class="imgArea">
						<img src="/resources/images/buildingTip/img12-5.png" alt="" />
						<img style="width:70%;" src="/resources/images/buildingTip/img12-5m.png" alt="" />
						<img style="width:70%;" src="/resources/images/buildingTip/img12-5-1m.png" alt="" />
					</div>
					<div class="txtArea">
						<div>
							<h4 class="depth">메인 > 건물 상세 > 메모 리스트 > 메모 상세 > 메모 수정</h4>
							<h4>선택된 메모를 조회하고 수정할 수 있는 화면입니다</h4>
							<dl>
								<dt><span>1.</span> 메모 내용</dt>
								<dd>- 메모 내용이 표시됩니다.</dd>
							</dl>
							<dl>
								<dt><span>2.</span> 사용위치</dt>
								<dd>- 3번 위치등록 추가하기 버튼을 통해 선택된 위치(등록될)를 보여줍니다.</dd>
							</dl>
							<dl>
								<dt><span>3.</span> 위치등록</dt>
								<dd>- 메모가 등록될 위치를 선택하는 팝업창이 보여집니다.</dd>
							</dl>
							<dl>
								<dt><span>3-1.</span> 위치등록 선택</dt>
								<dd>- 메모가 등록될 위치를 선택하는 팝업창이 보여집니다.</dd>
							</dl>
						</div>
					</div>
				</div>
			</div>

			<div class="manualArea">
				<h3>메모(수정)</h3>
				<div class="inBox">
					<div class="imgArea">
						<img src="/resources/images/buildingTip/img12-6.png" alt="" />
						<img style="width:70%;" src="/resources/images/buildingTip/img12-6m.png" alt="" />
						<img style="width:70%;" src="/resources/images/buildingTip/img12-6-1m.png" alt="" />
					</div>
					<div class="txtArea">
						<div>
							<h4 class="depth">메인 > 건물 상세 > 메모 리스트 > 메모 상세 > 메모 수정</h4>
							<h4>선택된 메모를 조회하고 수정할 수 있는 화면입니다</h4>
							<dl>
								<dt><span>4.</span> 스케줄</dt>
								<dd>- 스케줄 팝업창을 통해 스케줄 일정을 선택한 값이 보여집니다.</dd>
							</dl>
							<dl>
								<dt><span>4-1.</span> 스케줄 등록</dt>
								<dd>- 스케줄 등록이 가능한 팝업창 입니다.</dd>
								<dd>- 스케줄명, 주기종류(년간,월간,주간) 시작일,종료일 선택가능.</dd>
							</dl>
							<dl>
								<dt><span>4-2.</span> 스케줄 등록</dt>
								<dd>- 메모에 등록된 스케줄 형태.</dd>
							</dl>
						</div>
					</div>
				</div>
			</div>

			<div class="manualArea">
				<h3>메모(수정)</h3>
				<div class="inBox">
					<div class="imgArea">
						<img src="/resources/images/buildingTip/img12-7.png" alt="" />
						<img style="width:70%;" src="/resources/images/buildingTip/img12-7m.png" alt="" />
						<img style="width:70%;" src="/resources/images/buildingTip/img12-7-1m.png" alt="" />
					</div>
					<div class="txtArea">
						<div>
							<h4 class="depth">메인 > 건물 상세 > 메모 리스트 > 메모 상세 > 메모 수정</h4>
							<h4>선택된 메모를 조회하고 수정할 수 있는 화면입니다</h4>
							<dl>
								<dt><span>5.</span> 프로젝트</dt>
								<dd>- 스케줄 팝업창을 통해 프로젝트 일정을 선택한 값이 보여집니다.</dd>
							</dl>
							<dl>
								<dt><span>5-1.</span> 프로젝트 리스트</dt>
								<dd>- 프로젝트 선택이 가능합니다.</dd>
								<dd>
									- 지난 프로젝트를 선택하고자 하는경우 전체 버튼을 눌러 이전에 등록한 프로젝트가 보여지게 해야 합니다.
								</dd>
							</dl>
							<dl>
								<dt><span>5-2.</span> 프로젝트 등록</dt>
								<dd>- 메모에 등록된 스케줄 형태 입니다.</dd>
							</dl>
							<dl>
								<dt><span>6.</span> 이미지 목록</dt>
								<dd>- 등록된 이미지들을 표시하고, x버튼으로 삭제할 수 있습니다.</dd>
							</dl>
							<dl>
								<dt><span>7.</span> 첨부파일 목록</dt>
								<dd>- 첨부된 파일들을 표시하고, x버튼으로 삭제할 수 있습니다.</dd>
							</dl>
							<dl>
								<dt><span>8.</span> 첨부파일 버튼</dt>
								<dd>- 첩부파일 업로드 버튼을 클릭하면 첨부할 파일된 추가할 수 있습니다.</dd>
							</dl>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--// contents -->
	</div>
	<!--// container -->
</template>
<script>
import { isAuthorized } from '@/utils/cmm';
export default {
	methods: {
		onBuilding() {
			isAuthorized() ? this.$router.push({ name: 'buildingList' }) : this.$router.push({ name: 'login' });
		},
	},
};
</script>

<style scoped>
.depth {
	/* color: #0f80ff; */
	font-weight: bold;
}
.imgArea img {
	display: block;
	margin: 0px auto;
}
</style>
